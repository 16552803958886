@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('http://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,700');

$body-bg: #fff;
$body-color: #212529;
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,900;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,900;1,600;1,700;1,900&display=swap');
@font-face {
	font-family: 'Freezit ';
	src: url('../../fonts/Freizeit/FreizeitTrial-Medium.otf') format('opentype');
}
// @font-face {
// 	font-family: 'Poppins Italic';
// 	src: url('../../Poppins/Poppins-Italic.ttf') format('truetype');
// }

$theme-colors: (
	'blue': #123953,
	'green': #7cc141,
	'yellow': #fff163,
	'secondary': #e3e3e3,
	'annpak-color': #f1f8ea,
	'navbar-color': #565655,
	'site-table-color': #d6d5d3,
	'form-color': #565655,
	'input-color': #929291,
	'kopie-color': #e4e5e5,
	'footer-color': #868686,
	'p-color': #c5c6c6,
);

@import 'node_modules/bootstrap/scss/bootstrap';

html {
	font-size: 18px;
	@media (max-width: 1799.98px) {
		font-size: 17px;
	}
	@media (max-width: 1399.98px) {
		font-size: 16px;
	}
	@media (max-width: 1199.98px) {
		font-size: 13px;
	}
	@media (max-width: 991.98px) {
		font-size: 12px;
	}
	@media (max-width: 567.98px) {
		font-size: 11px;
	}
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
	.fw--#{$weight} {
		font-weight: $weight;
	}
}
@for $size from 12 through 96 {
	.fs--#{$size} {
		font-size: $size/16 + rem;
	}
}
.pointer-cursor {
	cursor: pointer !important;
}
.form-Input::placeholder {
	color: #000000;
}
* {
	font-family: 'Poppins';
}

.font-family {
	font-family: 'Poppins', sans-serif !important;
}
.font-family-freez {
	// font-family: 'Freizeit-Regular';
	// font-family: 'Freizeit-Medium';

	// font-weight: 600;
	font-family: 'Freizeit-Medium';
	font-size: 13px;
	line-height: 20px;
	font-weight: 600;
}
// .font-family-bold {
// 	font-family: 'Poppins-Bold';
// }
// .font-family-light {
// 	font-family: 'Poppins-Italic';
// }
// .font-family-heading {
// 	text-transform: uppercase;
// 	text-shadow: 0px 2px #123953;
// 	word-spacing: 0px;
// 	line-height: 19px;
// 	color: #123953;
// 	// font-weight: 1000;
// 	font-weight: 900;
// 	font-family: 'Poppins';
// }
.text-shadow-p {
	text-shadow: 0px 1px #123953;
}
.sidebar-logo {
	flex-basis: 20% !important;
}
.sideBar-text {
	flex-basis: 80% !important;
}
.line-height-1 {
	line-height: 1.2rem;
}
.height-fit-content {
	height: fit-content !important;
}

.Navbar {
	border-radius: 0px;
	filter: drop-shadow(6.928px -4px 16px rgba(0, 0, 0, 0.24));

	&_logo {
		@media (max-width: 600px) {
			justify-content: left;

			width: 185px;
			height: 38px;
			margin-left: 0 !important;
		}
		@media (max-width: 366px) {
			// justify-content: left;
			// font-weight: 600;
			// width: 37%;
			font-weight: 600;
			width: 38%;
			height: auto;
		}
	}
	&_text {
		@media (max-width: 600px) {
			justify-content: left;
			font-weight: 600;
			width: 44%;
			font-size: 14px !important;
		}

		@media (max-width: 395px) {
			justify-content: left;
			font-weight: 600;
			width: 44%;
			font-size: 13px !important;
		}

		@media (max-width: 366px) {
			// justify-content: left;
			// font-weight: 600;
			// width: 37%;
			font-size: 14px !important;
			font-weight: 600;
			width: 60%;
			position: relative;
			left: 15px;
		}
	}
}

.Header {
	background: linear-gradient(to right, #123953 70%, #ffff 30%);
	border-radius: 1px solid #000000;
	width: 100%;
	height: 650px;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;

	.arrow {
		width: 20px;
		height: 20px;
	}
	@media (min-width: 1440.98px) {
		height: 711px;
	}
	@media (max-width: 1024.98px) {
		height: 526px;
	}
	@media (max-width: 768.98px) {
		height: 472px;
	}
	@media screen and (max-width: 600.98px) {
		border-radius: 0 !important;
		background: transparent !important;
		height: auto;
		.arrow {
			width: 15px;
			height: 15px;
		}
	}

	&_paragraph {
		@media (max-width: 768.98px) {
			// height: 500px;
			height: 368px;
		}
		@media (max-width: 600.98px) {
			// height: 500px;
			height: 473px;
			// height: 368px;
		}
		@media (max-width: 375.98px) {
			height: 454px;
		}
		@media (max-width: 320.98px) {
			height: 507px;
		}
	}
}

.MobileImage {
	position: relative;
	float: right;
	z-index: 1;
	margin-right: -27%;
	margin-top: -8% !important;
	width: 80%;

	@media (max-width: 950px) {
		// position: relative;
		// float: right;
		// z-index: 1;
		// margin-right: -26%;
		// width: 75%;
	}
	@media (max-width: 576.98px) {
		// position: relative;
		// margin: 5% !important;
		// z-index: 1;
		// background: white;
		// padding: 4%;
		display: none !important;
	}
	&_hundredImage {
		position: absolute;
		z-index: 9;
		bottom: 26px;
		width: 35%;
		right: 0px;
		@media (max-width: 395px) {
			justify-content: left;
			font-weight: 600;
			width: 30%;
			font-size: 13px !important;
		}
	}
}
.hundredImage2 {
	position: absolute;
	// margin-top: -58%;
	// margin-right: -17%;
	z-index: 999;
	bottom: 26px;
	width: 35%;
	right: 0px;
}
.promotionSection {
	&_logo-text {
		// height: 12%;
		// width: 12%;
		height: 9%;
		width: 9%;
		// promotion text
		// height: 85px;
		// width: 100%;
		position: absolute;
		top: 1;
		left: 0;
		@media (max-width: 600.98px) {
			height: 8%;
			width: 8%;
		}
	}
	&_promotion-heading {
		@media (max-width: 600.98px) {
			text-align: center;
			// font-size: 2.7rem;
			font-size: 2.7rem;
			margin-bottom: 14px;
		}
	}
	&_promotion_image {
		height: 130px;
		width: 75%;
		@media (max-width: 600px) {
			height: auto;
			width: 75%;
		}
	}
}

.rounded-cornor {
	border-radius: 16px;
	border: 2px solid #7cc141;
}
.promotion-mobile {
	position: relative;
	margin-top: -13%;
	margin-left: 33%;
	@media (max-width: 2560.98px) {
		position: relative;
		margin-top: -8%;
		margin-left: 41%;
	}
	@media (max-width: 1440.98px) {
		position: relative;
		margin-top: -14%;
		margin-left: 34%;
		// position: relative;
		// margin-left: 27%;
		// margin-top: -17%;
	}
	@media (max-width: 1024.98px) {
		position: relative;
		margin-top: -18%;
		margin-left: 26%;
	}
	@media (max-width: 768.98px) {
		position: relative;
		margin-top: -24%;
		margin-left: 14%;
	}
	@media (max-width: 576.98px) {
		display: none !important;
	}
	.down-arrow {
		margin-bottom: -3%;
	}
}
.Beginnen_button {
	@media (max-width: 576.98px) {
		font-size: 11px !important;
		padding: 4px !important;
	}
}

.label {
	height: 10%;
	width: 10%;
}
.form-height {
	border-radius: 30px;
	filter: drop-shadow(6.928px -4px 16px rgba(0, 0, 0, 0.24));
	height: 46rem;
	// margin-bottom: -9%;
	margin-bottom: -15%;
	@media (min-width: 481px) and (max-width: 768px) {
		padding: 2% !important;
	}

	@media (max-width: 576px) {
		// border-radius: 0px;
		margin-top: -24%;
		height: 46rem !important;
		margin-bottom: 1% !important;
		width: 90%;
		// filter: inherit;
		// border: dashed;
	}

	/* Landscape phones and portrait tablets */
	@media (max-width: 768px) {
		// border-radius: 0px !important;
		// height: 44rem;
		// margin-bottom: 1%;
		// height: 42rem;
		// height: 45.5rem;
		padding: 2% !important;
		height: 46.5rem !important;

		// margin-bottom: -10%;
		// margin-top: -30%;
	}
}
.Annpak {
	.houseAnnpak {
		position: relative !important;
		margin-bottom: -5% !important;
	}
}

// .down-promotion-arrow {
// 	position: relative;
// 	width: 5%;
// 	margin-bottom: -10% !important;
// 	@media (max-width: 600px) {
// 		position: relative;
// 		width: 15%;
// 		margin-bottom: -30% !important;
// 	}
// }
.annpak_Al_text {
	@media (max-width: 600.98px) {
		font-size: 15px;
	}
}
.down-promotion-icon {
	position: relative;
	width: 5%;
	margin-bottom: -6.5% !important;
}
.AwardSection {
	&_logos_Column {
		@media (max-width: 768px) {
			border: none !important;
		}
	}
	&_text_Column {
		@media (max-width: 768px) {
			border: none !important;
		}
	}
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	//   border: none !important;
	border-color: #ced4da;
	background-image: none !important;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
	//   box-shadow: none;
}
.form-control:required:is-invalid {
	//   border: none !important;
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
	background-repeat: no-repeat;
	box-shadow: #808080 !important;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
